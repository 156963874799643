<template>
    <div class="delectLabel scrolling">
        <van-search @search="onSearch" v-model="queryParam.name" placeholder="请输入标签名称" />
        <div class="checkboxContainer">
            <van-checkbox-group v-model="result" v-for="tag in tags" :key="tag.id">
                <div class="cell">
                    <van-checkbox :name="tag.id">{{ tag.name }}</van-checkbox>
                </div>
            </van-checkbox-group>
        </div>
        <div class="footer">
            <a-button block @click="batchDelete" type="primary" size="small">删除</a-button>
        </div>
    </div>
</template>
<script>
import { documentLableDelete, documentLablePage } from '@/api/modular/fileStream/documentLabelManage'
export default {
    data() {
        return {
            queryParam: {},
            tags: [],
            result: [],
        }
    },
    mounted() {
        this.getTags()
    },
    methods: {
        onSearch() {
            this.getTags()
        },
        /**
         * 批量删除
         */
        batchDelete() {
            this.documentLableDelete(this.result.map((j) => ({ id: j })))
        },
        documentLableDelete(record) {
            documentLableDelete(record).then((res) => {
                if (res.success) {
                    this.$message.success('删除成功')
                    this.$router.go(-1)
                } else {
                    this.$message.error('删除失败') // + res.message
                }
            })
        },
        getTags() {
            documentLablePage(this.queryParam).then((res) => {
                if (res.code === 200) {
                    this.tags = res.data.rows.map((j) => ({ ...j, unfold: false, persons: 0 }))
                    console.log(this.tags)
                } else {
                    res.message && this.$message.warning(res.message)
                }
            })
        },
    },
}
</script>
<style lang="less" scoped>
.delectLabel {
    .checkboxContainer {
        padding: 0 16px;
        .cell {
            margin-bottom: 5px;
            line-height: 1;
        }
    }
    .footer {
        position: fixed;
        width: 90%;
        left: 50%;
        transform: translateX(-50%);
        bottom: 45px;
        text-align: center;
        .addLabel {
            margin-right: 28px;
        }
    }
}
</style>
